
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.link {
  cursor: pointer;

  &:hover {

    svg,
    .text {
      color: $warning;
      transition: .2s;
    }
  }
}
