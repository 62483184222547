
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
$gutterSizes: (4, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80);

.cell {
  flex-grow: 0;
  flex-shrink: 0;
  outline: none;
}

@each $size in $gutterSizes {
  .gutter-#{$size} {
    padding: #{math.div($size, 2)}rem;
  }
}
