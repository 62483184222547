
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.button {
  position: relative;
  display: inline-block;
  overflow: hidden;
  line-height: 1.2;
  white-space: nowrap;
  text-align: center;
  vertical-align: top;
  background-position: 100% 0 !important;
  background-size: 210% 210% !important;
  border: none;
  box-shadow: none;
  cursor: pointer;
  transition: all .15s ease;
  user-select: none;

  &:focus {
    outline: none;
  }

  &:hover {
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    transform: translateY(-1px);
  }
}

.disabled {
  cursor: default;
  opacity: .5;

  &:hover {
    box-shadow: none;
    transform: none;
  }
}

.loading {
  @include block-loader;

  span {
    color: transparent;
  }
}

.uppercase {
  text-transform: uppercase;
}

.fullWidth {
  width: 100%;
}

.size-md {
  padding: 15rem 48rem;
  border-radius: 7rem;

  .text {
    font-size: 16rem !important;
  }
}

.size-sm {
  padding: 4rem 16rem;
  border-radius: 7rem;

  .text {
    font-size: 14rem !important;
  }
}

.color-blue {
  background: $info;
  background-color: $info;
  background-image: linear-gradient(to bottom left, $info, $info-states, $info);
}

.color-orange {
  background: $warning;
  background-color: $warning;
  background-image: linear-gradient(to bottom left, $warning, $warning-states, $warning);
}

.color-purple {
  background: #6e6591;
  background-color: #6e6591;
  background-image: linear-gradient(to bottom left, #9b8cc9, #6e6591, #9b8cc9);
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@include mobile {

  .fullWidthOnMobile {
    width: 100%;
  }
}
