
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.container {
  transform: translateY(0);
  animation: openModal .3s ease;
}

.modal {

  @include dark-theme {
    background-color: $dark-background-states;
    box-shadow: 0 1px 20rem 0 rgb(0, 0, 0, 10%);
  }

  @include light-theme {
    background-color: $white;
    box-shadow: 0 1px 15rem 0 rgb(123, 123, 123, 5%);
  }
}

.closeButton {
  position: absolute;
  top: 8rem;
  right: 8rem;
  z-index: 10;
}

.close {

  .container {
    transform: translateY(100%);
    animation: closeModal .4s ease;
  }

  .overlay {
    opacity: 0;
    transition: .4s;
  }
}

@include mobile {

  .container {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .modal {
    width: 100%;
    min-height: 100%;
  }
}

@include except-mobile {

  .overlay {
    display: grid;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 16rem;
  }

  .modal {
    width: 500rem;
    max-width: 100%;
    min-height: 150rem;
    overflow: hidden;
    border-radius: 8rem;
  }
}

@keyframes openModal {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes closeModal {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}
