
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 400rem;
  min-height: 423rem;
  overflow: hidden;
  border: none !important;
}
