
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.container {

  &.withIcon::before {
    position: absolute;
    top: 50%;
    right: -3rem;
    width: 6rem;
    height: 6rem;
    margin-top: -3rem;
    background-color: $success;
    border-radius: 50%;
    content: '';
  }

  &.expired.withIcon::before {
    animation: pulsation 1.5s infinite linear;
  }
}

@keyframes pulsation {
  0% {
    transform: scale(1.2);
    opacity: 1;
  }
  50% {
    transform: scale(.5);
    opacity: .3;
  }
  100% {
    transform: scale(1.2);
    opacity: 1;
  }
}
