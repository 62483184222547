
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.box {
  width: 36rem;
  height: 36rem;

  @include dark-theme {
    border: solid 1px lighten($dark-background-states, 5%);
  }

  @include light-theme {
    border: solid 1px darken($white, 5%);
  }

  &.active {
    background-color: $info;
    border-color: $info;

    &:hover {
      border-color: $info;
    }
  }

  &:hover {
    border-color: $dark-gray;
  }
}
