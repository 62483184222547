
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
$hidenPosition: translateY(calc(100% + 58rem));

.container {
  position: fixed;
  z-index: $layer-modal - 1;
  background-color: $info;
  transform: translateY(0);
  opacity: 1;
  animation: openLoader 0.3s ease-in-out;

  &.closed {
    transform: $hidenPosition;
    opacity: 0;
    animation: hideLoader 0.3s ease-in-out;
  }
}

.loader {
  flex-shrink: 0;
}

@include mobile {

  .container {
    right: 16rem;
    bottom: 16rem;
    left: 16rem;
  }
}

@include except-mobile {

  .container {
    right: 32rem;
    bottom: 58rem;
  }
}

@keyframes openLoader {
  from {
    transform: $hidenPosition;
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes hideLoader {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: $hidenPosition;
    opacity: 0;
  }
}
