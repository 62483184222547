
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.switch {
  position: relative;
  display: block;
  width: 46rem;
  height: 26rem;
  background-color: $light-background;
  border-radius: 26rem;
  outline: none;
  cursor: pointer;
  transition: .2s;
}

.control {
  position: absolute;
  top: 2rem;
  left: 2rem;
  width: 22rem;
  height: 22rem;
  background-color: $info;
  border-radius: 50%;
  transition: .2s;
}

.active {
  background-color: $info;

  .control {
    left: calc(100% - 24rem);
    background-color: $light-background;
  }
}

