
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.container {
  position: absolute;
  top: -8rem;
  left: 50%;
  z-index: 20;
  display: none;
  margin-left: -88rem;
  padding-top: 42rem;
  opacity: 0;

  &.opened {
    display: block;
    opacity: 1;
    animation: show-content .2s ease-in-out;
  }

  @include dark-theme {
    background-color: $dark-background-states;
    box-shadow: 0 1px 20rem 0 rgb(0, 0, 0, 50%);
  }

  @include light-theme {
    background-color: $white;
    box-shadow: 0 1px 15rem 0 rgb(123, 123, 123, 50%);
  }
}

@keyframes show-content {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
