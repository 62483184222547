
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.container {
  height: 53rem;

  @include dark-theme {
    border: solid 1px lighten($dark-background-states, 20%);
  }

  @include light-theme {
    border: solid 1px darken($gray-100, 7%);
  }

  &.withHover:hover {

    @include dark-theme {
      border-color: lighten($info, 25%);
    }

    @include light-theme {
      border-color: darken($info, 25%);
    }
  }
}

.titleContainer {
  position: absolute;
  top: -10rem;
  left: 0;
  width: 100%;
}

.title {
  padding: 0 8rem;

  @include dark-theme {
    color: lighten($info, 25%);
    background-color: $dark-background-states;
  }

  @include light-theme {
    color: darken($info, 25%);
    background-color: $white;
  }
}
