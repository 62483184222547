
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.container {
  height: 34rem;

  @include dark-theme {
    border: solid 1px $default;
  }

  @include light-theme {
    border: solid 1px #b6b9c5;
  }

  &.compact .text {
    font-size: 13rem;
  }
}

.text {
  margin-top: 2rem;
  overflow: hidden;
  font-size: 16rem;
  line-height: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
}
