
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.container {
  font-size: 0;
}

.icon {
  display: inline-block;
}

// Put new size here
$sizes: (14, 16, 20, 24, 28, 40);

@each $size in $sizes {

  .size-#{$size} {
    min-width: #{$size}rem;
    height: #{$size}rem;
    font-size: #{$size}rem;
  }
}

.opacity-5 {
  opacity: .5;
}

.color-info {
  color: $info;
}

.color-black {
  color: $black;
}

.color-white {
  color: $white;
}

.color-success {
  color: $success;
}

.color-green {
  color: $green;
}

.color-warning {
  color: $warning;
}

.color-primary {
  color: $primary;
}

.color-dark-gray {
  color: $dark-gray;
}

.color-green-states {
  color: $green-states;
}

.color-danger-states {
  color: $danger-states;
}

.color-inverse {
  @include inverse-color;
}

.color-default {
  @include default-color;
}
