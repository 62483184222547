
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.compact {
  width: 100%;
  line-height: 1;
  background-color: $white;

  .content {
    width: 100%;
  }
}

.container {
  height: 34rem;
  font-size: 16rem;
  border-right: none;
  border-radius: 8rem;
  border-top-left-radius: 8rem;
  border-bottom-left-radius: 8rem;

  @include dark-theme {
    border: solid 1px $default;
  }

  @include light-theme {
    border: solid 1px #b6b9c5;
  }
}

.text {
  line-height: 25rem;
}

.imageContainer {
  margin-top: -1px;
  line-height: 0;
}

.loading {

  .leftBox {
    @include dark-theme {
      @include block-loader(20rem, 3rem, lighten($default, 20%));
    }

    @include light-theme {
      @include block-loader(20rem, 3rem, #a3a7b9);
    }

    span {
      color: transparent;
    }
  }
}
