
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.root {
  text-align: left;

  &.checked {

    .icon {
      transform: scale(1);
      opacity: 1;
    }

    .iconContainer {
      background-color: $info;
      border: solid 1px $info;
    }
  }

  &.disabled {

    .iconContainer {
      opacity: .5;
    }
  }
}

.iconContainer {
  flex: none;
  width: 20rem;
  height: 20rem;
  border: solid 1px $gray-600;
  border-radius: 4rem;
  transition: .2s;
}

.icon {
  transform: scale(0);
  opacity: 0;
  transition: .2s;
}
