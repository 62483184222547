
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.mobileMenu {
  width: 190rem;
}

.menu {
  width: 160rem;
}

@include desktop {

  .dropdownContainer {
    margin-right: 6rem;
  }
}
