
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
$gutterSizes: (4, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80);

@each $size in $gutterSizes {
  .gutter-#{$size} {
    margin: -#{math.div($size, 2)}rem;
  }
}

.grid {
  display: flex;
}

.flexWrap {
  flex-wrap: wrap;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-baseline {
  align-items: baseline;
}

.align-stretch {
  align-items: stretch;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}
