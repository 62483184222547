
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.container {
  height: 34rem;
  overflow: hidden;

  @include dark-theme {
    border: solid 1px $default;
  }

  @include light-theme {
    border: solid 1px #b6b9c5;
  }

  &.compact {
    line-height: 1;
    background-color: $white;
  }
}

.imageContainer {
  margin-top: -1px;
  line-height: 0;
}

.text {
  line-height: 25rem;
}

.buyButton {
  height: 32rem;
  margin: 0 -12rem 0 auto;
  padding: 0 8rem;
  transition: .2s;

  @include dark-theme {
    background-color: $default;

    &:hover {
      background-color: darken($default, 10%);
    }
  }

  @include light-theme {
    background-color: #b6b9c5;

    &:hover {
      background-color: darken(#b6b9c5, 10%);
    }
  }
}

.loading {
  @include dark-theme {
    @include block-loader(20rem, 3rem, lighten($default, 20%));
  }

  @include light-theme {
    @include block-loader(20rem, 3rem, #a3a7b9);
  }

  .text {
    color: transparent;
  }
}

@include desktop {

  .buyButton {
    margin: 0 -12rem 0 12rem;
  }
}
