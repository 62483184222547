
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.button {
  position: relative;
  display: block;
  width: 160rem;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 8rem;
  cursor: pointer;
}

.disabled {
  opacity: .5;
}

.imageContainer {
  width: 40rem;
  filter: brightness(2);
}

.imageBackground,
.imageContainer,
.button {
  height: 34rem;
}

.gnosis {
  background: linear-gradient(-45deg, #60dabd, #24806a);
}

.mainnet {
  background: linear-gradient(-45deg, #8f87eb, #453abd);
}

.testnet {
  background: linear-gradient(-45deg, #adadad, #363636);
}

.harbourMainnet {
  background: linear-gradient(-45deg, #7590db, #2f5fa8);
}

.icon {
  position: absolute;
  top: 50%;
  right: 4rem;
  margin-top: -6rem;

  &.rotate {
    margin-top: -7rem;
    transform: rotate(180deg);
  }
}
