
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.button {
  position: relative;
  width: 24rem;
  height: 20rem;
  margin-left: 16rem;
}

.bar {
  position: absolute;
  @include default-color(background-color);
}

@include desktop {

  .button {
    position: absolute;
    top: 29px;
    left: 12rem;
  }

  .bar {
    transition: .2s;
  }

  .bar1,
  .bar2,
  .bar3 {
    left: 8rem;
    width: calc(100% - 8rem);
    height: 2rem;
    border-radius: 2rem;
  }

  .bar4,
  .bar5,
  .bar6 {
    left: 0;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
  }

  .bar1,
  .bar4 {
    top: 0;
  }

  .bar2 {
    top: 50%;
    margin-top: -1rem;
  }

  .bar5 {
    top: 50%;
    margin-top: -2rem;
  }

  .bar3,
  .bar6 {
    bottom: 0;
  }

  .opened {

    .bar1,
    .bar2,
    .bar3 {
      left: 0;
      width: 100%;
      height: 2rem;
      margin-left: 0;
      border-radius: 2rem;
    }

    .bar1 {
      top: 0;
    }

    .bar2 {
      top: 50%;
      margin-top: -1rem;
    }

    .bar3 {
      bottom: 0;
    }

    .bar4,
    .bar5,
    .bar6 {
      left: -16rem;
      opacity: 0;
    }
  }
}

@include except-desktop {

  .bar {
    left: 0;
    width: 100%;
    height: 2px;
    margin-left: 0;
    border-radius: 2px;
    transition: .7s;
  }

  .bar1 {
    top: 0;
  }

  .bar2 {
    top: 50%;
    margin-top: -1px;
  }

  .bar3 {
    bottom: 0;
  }

  .bar4,
  .bar5,
  .bar6 {
    opacity: 0;
  }

  .opened {

    .bar1 {
      top: 50%;
      margin-top: -2px;
      transform: rotate(45deg);
    }

    .bar2 {
      width: 0;
      opacity: 0;
    }

    .bar3 {
      top: 50%;
      margin-top: -2px;
      transform: rotate(-45deg);
    }
  }
}
