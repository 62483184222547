
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.menu {
  line-height: 0;
}


@include desktop {

  :global(.withBanner) {

    .menu:not(.fixed) {
      top: 56rem;
      height: calc(100vh - 56rem - 22rem);
    }
  }

  .menu {
    position: absolute;
    top: 0;
    left: 20rem;
    width: 80rem; // todo remove
    height: calc(100vh - 20rem);
  }

  .fixed {
    position: fixed;
    top: 0;
    z-index: $layer-menu;
    width: 24rem;
  }
}
