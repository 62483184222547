
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.container {
  width: 100%;
}

.text {
  position: relative;
  z-index: 10;
  height: 50rem;
  line-height: 50rem;
  transition: .3s;
}
