
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.wrapper {
  line-height: 0;
}

.container {
  height: 26rem;
  padding-right: 8rem;
  padding-left: 2rem;
  overflow: hidden;
  border-radius: 26rem;
  transition: all .3s ease;

  @include light-theme {
    color: $white;
    background-color: $info;
  }

  @include dark-theme {
    color: $default;
    background-color: $light-background;
  }

  &:focus-within {
    border-color: $info;
  }
}

.image {
  max-width: 100%;
  max-height: 100%;
  border: 1px solid rgba($info, 0.15) !important;
  border-radius: 100%;
}

.iconContainet,
.selectedIconContainet {
  width: 22rem;
  height: 22rem;
  background-color: $info;
  border-radius: 50%;
  transition: .2s;
}

.selectedIconContainet {

  @include light-theme {
    background-color: $white;
  }

  @include dark-theme {
    background-color: $info;
  }
}

.selectedIcon {

  @include light-theme {
    color: $info;
  }

  @include dark-theme {
    color: $white;
  }
}

.select {
  flex: 1 1 0;
}

.option {
  transition: .1s;

  &:hover {
    background-color: darken($white, 3%);
  }

  & + .option {
    border-top: 1rem solid $gray-200;
  }
}

.wFull {
  width: 100%;
}

.block {
  display: block;
}
