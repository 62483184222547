
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
@include except-mobile {

  .box,
  .hash {
    width: 25%;
  }

  .hash {
    padding: 12rem;
  }
}

@include mobile {

  .box {
    width: 30%;
  }

  .hash {
    width: 10%;
  }
}
