
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.root {
  position: relative;
  overflow: hidden;
  border-radius: 7rem;
  transition: all .3s ease;

  @include light-theme {
    border: solid 1px rgba(29, 37, 59, .5);
  }

  @include dark-theme {
    border: solid 1px $border;
  }

  &:focus-within {
    border-color: $info;
  }

  &.errored {
    border-color: $danger-states !important;
  }

  &.success:focus-within {
    border-color: $success;
  }
}

.withRightIcon {

  .input {
    padding-right: 32rem;
  }
}

.input {
  width: 100%;
  padding: 8rem 16rem;
  background: transparent;
  border: none;
  outline: none;

  @include light-theme {
    color: $black;

    &::placeholder {
      color: $gray-700;
    }
  }

  @include dark-theme {
    color: darken($white, 15%);

    &::placeholder {
      color: $gray-500;
    }
  }
}

.disabled {
  cursor: not-allowed;
}

.rightIcon {
  position: absolute;
  top: 50%;
  right: 8rem;
  margin-top: -8rem;
}

.size-38 {
  .input,
  .input::placeholder {
    font-size: 12rem;
    line-height: 1.7;
  }
}

.size-50 {
  .input,
  .input::placeholder {
    font-size: 16rem;
    line-height: 2;
  }
}
