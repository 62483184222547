
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.root {
  padding-left: 30rem;
  cursor: pointer;
  transition: .2s;

  &:hover {
    background-color: rgba($info, .9);
  }

  &::before {
    position: absolute;
    top: 50%;
    left: -4rem;
    width: 6rem;
    height: 6rem;
    margin-top: -3rem;
    background-color: $white;
    border-radius: 50%;
    opacity: 0;
    transition: .2s;
    content: '';
  }

  &.active::before {
    left: 12rem;
    opacity: 1;
  }
}

.content {
  position: relative;
  z-index: 10;
}

.icon {
  width: 20rem !important;
}


@keyframes dubleBackground {
  0% {
    width: 0%;
    opacity: 0;
  }
  100% {
    width: 50%;
    opacity: 1;
  }
}
