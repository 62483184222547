
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.container {
  transform: translateY(-100%);
  opacity: 0;
}

.showContent {
  animation: show-content .5s ease-in-out;
}

@keyframes show-content {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@include desktop {

  .content {
    height: 80rem;
  }
}
