
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.container {

  &:nth-child(odd) {

    @include dark-theme {
      background-color: lighten($dark-background-states, 5%);
    }

    @include light-theme {
      background-color: darken($white, 5%);
    }
  }
}

@include except-mobile {

  .box,
  .hash {
    width: 25%;
  }

  .hash {
    padding: 12rem;
  }

  .link {
    transition: .1s;

    &:hover {
      color: $info-states;
    }
  }
}

@include mobile {

  .box {
    width: 30%;
  }

  .hash {
    width: 10%;
  }
}
