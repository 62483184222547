
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.container {
  position: absolute;
  right: 0;
  z-index: $layer-header + 1;
  width: 180rem;
  @include animate-show-content();

  &::before {
    position: absolute;
    right: 12rem;
    border-right: 4rem solid transparent;
    border-left: 4rem solid transparent;
    transition: .1s;
    content: '';
  }
}

.top {
  bottom: calc(100% + 16rem);

  &::before {
    bottom: -5rem;
    border-top: 5rem solid $white;
  }
}

.bottom {
  top: calc(100% + 16rem);

  &::before {
    top: -5rem;
    border-bottom: 5rem solid $white;
  }
}

.dropdown {
  background-color: $white;
  box-shadow: 0 10rem 50rem 0 rgb(0, 0, 0, 20%);
}
