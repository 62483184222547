
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.row {
  display: flex;
}

.inline {
  display: inline-flex;
}

.fullHeight {
  height: 100%;
}

.wrap {
  flex-wrap: wrap;
}

.direction-row {
  flex-direction: row;
}

.direction-row-reverse {
  flex-direction: row-reverse;
}

.direction-column {
  flex-direction: column;
}

.direction-column-reverse {
  flex-direction: column-reverse;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-baseline {
  align-items: baseline;
}

.align-stretch {
  align-items: stretch;
}
