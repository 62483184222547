
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.footer {
  padding: 16rem;
  animation: show-content .5s ease-in-out;
}

@include desktop {

  .footer {
    padding: 16rem 32rem 16rem 130rem;
    transition: .2s;

    @include main-menu-open {
      padding-left: 250rem;
    }
  }
}

@keyframes show-content {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
