
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.animation {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom left, lighten($warning, 10%), darken($warning-states, 10%));
  background-size: 200% 200%;
  animation: gradient linear 3s infinite;
}

.content {
  position: relative;
  z-index: 2;
  margin: 2rem;

  @include dark-theme {
    background-color: $dark-background-states;
  }

  @include light-theme {
    background-color: $white;
  }
}

.text {

  span,
  a {
    color: $info;
  }

  svg {
    color: $info;
    font-size: 14rem;
  }
}

@include mobile {

  .container {
    height: 100vh;
    margin: -24rem;
  }

  .content {
    height: calc(100% - 4rem);
  }
}

@include except-mobile {

  .modal {
    width: 600rem;
  }

  .container {
    margin: -32rem;
    border-radius: 8rem;
  }

  .content {
    border-radius: 6rem;
  }
}

@keyframes gradient {
  0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
