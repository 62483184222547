
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.loader {
  width: 20rem;
  height: 20rem;
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  border-radius: 50%;
  transform: translateZ(0);
  animation: loader .8s infinite linear;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 50%;
    background: $white;
    border-radius: 100% 0 0;
    content: '';
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 75%;
    height: 75%;
    margin: auto;
    background: $info;
    border-radius: 50%;
    content: '';
  }
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
