
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
html,
body {
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

html {
  font-size: 1px;
  line-height: 1.4;
}

body {
  position: relative;
  font-weight: 400;
  font-size: 14rem;
  font-family: Poppins, sans-serif !important;
  text-rendering: optimizeLegibility;
  -webkit-overflow-scrolling: touch;
  animation: hideScroll 1.2s ease-in-out;

  &.body-light-theme {
    background-color: $light-background;
  }

  &.body-dark-theme {
    background-image: linear-gradient(#1e1e2f, $dark-background);
  }

  &::before,
  &::after {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    content: '';
  }

  &::before {
    height: 100%;
  }

  &.mainnet {

    &::before {
      background: radial-gradient(100% 100% at 50% -20%, rgba(55, 43, 182, 20%) 0, rgba(255, 255, 255, 0) 100%);
    }
  }

  &.gnosis {

    &::before {
      background: radial-gradient(100% 100% at 50% -20%, rgba(43, 182, 85, 0.1) 0, rgba(255, 255, 255, 0) 100%);
    }
  }


  &.testnet {

    &::before {
      background: radial-gradient(100% 100% at 50% -20%, rgba(83, 79, 82, 0.1) 0, rgba(255, 255, 255, 0) 100%);
    }
  }

  &.harbourMainnet {

    &::before {
      background: radial-gradient(100% 100% at 50% -20%, rgba(58, 82, 189, 0.1) 0, rgba(255, 255, 255, 0) 100%);
    }
  }
}

#__next {
  position: relative;
  z-index: 1;
  min-height: 100%;
}

button,
input,
optgroup,
select,
textarea {
  font-family: Poppins, sans-serif !important;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

// https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge-what-is-allowed
.grecaptcha-badge {
  visibility: hidden;
}

@include mobile {

  html {
    font-size: 0.267vw;
  }
}

@include except-desktop {

  body {
    transition: .2s;

    &.main-menu-open {
      margin-left: 200rem;
      overflow: hidden;
    }
  }
}

@keyframes hideScroll {
  0%,
  100% {
    height: 100vh;
    overflow: hidden;
  }
}


// QR code styles

#reader__scan_region {
  display: flex;
  justify-content: center;
  flex: 1;
}

#reader__header_message {
  border: none!important;
}

#reader__dashboard_section {
  color: $dark-gray;
}

#html5-qrcode-button-camera-permission,
#html5-qrcode-button-camera-start,
#html5-qrcode-button-camera-stop {
  display: inline-block;
  padding: 10rem 15rem;
  border-radius: 8rem;
  margin: 10rem 0;
  color: $white;
}


#html5-qrcode-button-camera-permission {
  background: linear-gradient(-45deg, #8f87eb, #453abd);
}

#html5-qrcode-button-camera-start {
  background: linear-gradient(-45deg, #60dabd, #24806a);
}

#html5-qrcode-button-camera-stop {
  background: linear-gradient(-45deg, #da6060, #802424);
}
