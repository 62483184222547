
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.container {

  a {
    color: $info;
    transition: .1s;

    &:hover {
      color: $warning;
    }
  }

  span {
    @include default-color();
  }
}

@include mobile {

  .container {
    max-width: 60%;
  }
}
