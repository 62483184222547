
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.notify {
  position: fixed;
  bottom: 12rem;
  left: calc(50% - 12rem);
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 24rem);
  max-width: 660rem;
  background-color: $info;
  transform: translateX(-50%);

  button {
    margin-left: 10rem;
  }

  > span {
    text-align: center;
  }

  a {
    color: $white;
    text-decoration: underline;
  }
}
