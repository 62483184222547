
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.buttonText {
  transition: .1s;

  &:hover {
    color: $warning;
  }
}
