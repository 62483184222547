
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.tabsContainer {
  cursor: pointer;
}

.background {
  position: absolute;
  top: 0;
  z-index: 1;
  height: 100%;
  background: $info;
  background-color: $info;
  background-image: linear-gradient(135deg, $info, $info-states);
  border-radius: 7rem;
  box-shadow: 0 10rem 25rem 0 rgb(0 0 0 / 30%);
  transition: .2s;
}
