
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  opacity: 0;
  transition: .5s;

  &.show {
    opacity: 1;
  }
}

.content {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  margin: 0 auto;
}

.animation {
  flex: 1;
  max-width: 100%;
}

@include except-mobile {

  .animation {
    opacity: 0;

    &.start {
      opacity: 1;
      animation: show-content 1.1s ease-in-out;
    }
  }
}

@include desktop {

  .content {
    max-width: 1400rem;
    padding: 0 30px 30px 130px;
    transition: .2s;

    @include main-menu-open {
      max-width: 1550rem;
      padding-left: 250rem;
    }
  }
}

@include except-desktop {

  .content {
    padding: 16px;
  }
}

@keyframes show-content {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
