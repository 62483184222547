
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.notifications {
  position: fixed;
  z-index: $layer-modal + 1;
}


@include mobile {

  .notifications {
    top: 8rem;
    right: 16rem;
    left: 16rem;
  }
}

@include desktop {

  .notifications {
    top: 0;
    left: 50%;
    width: 520rem;
    margin-left: -260rem;
  }
}
