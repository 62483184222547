
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.button {
  position: relative;
  display: block;
  width: 160rem;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 8rem;
  cursor: pointer;

  &:hover {
    @include dark-theme {
      background-color: lighten($dark-background-states, 10%);
    }

    @include light-theme {
      background-color: darken($white, 10%);
    }
  }
}

.disabled {
  opacity: .5;

  &:hover {
    background-color: transparent !important;
  }
}

.imageContainer {
  position: relative;
  width: 40rem;

  &::before {
    position: absolute;
    top: 5rem;
    left: 8rem;
    width: 24rem;
    height: 24rem;
    border-radius: 50%;
    content: '';

    @include dark-theme {
      background-color: white;
    }

    @include light-theme {
      background-color: $dark-background-states;

      img {
        filter: brightness(2);
      }
    }
  }

  @include light-theme {
    img {
      filter: brightness(2);
    }
  }
}

.imageBackground,
.imageContainer,
.button {
  height: 34rem;
}
