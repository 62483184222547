
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.banner {
  z-index: 1;
  width: 100%;
  text-align: center;
  background: linear-gradient(90deg, rgba(109, 183, 219, 1) 0%, rgba(174, 224, 182, 1) 50%, rgba(195, 228, 139, 1) 100%);
  background-color: rgb(109, 183, 219);
}

.link {
  display: block;
  width: 100%;
}

.icon {
  margin-left: 8rem;
}
