
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.bone {
  background: #eaecf1;
  mix-blend-mode: multiply;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    animation: shine-lines 2s infinite;
    content: '';

    @include dark-theme {
      background: linear-gradient(90deg, transparent, rgba($white, 0.8), transparent);
    }

    @include light-theme {
      background: linear-gradient(90deg, transparent, rgba(lighten(#eaecf1, 10%), .5), transparent);
    }
  }
}

@keyframes shine-lines {
  100% {
    transform: translateX(100%);
  }
}
