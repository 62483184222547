
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.item {
  cursor: pointer;
  transition: .1s;

  &:hover {
    background-color: darken($white, 3%);
  }
}
