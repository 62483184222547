
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
$sizes: (10, 12, 14, 16, 18, 20, 24, 28, 32, 40, 50);

@each $size in $sizes {
  .size-#{$size} {
    font-size: #{$size}rem;
  }
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.noWrap {
  white-space: nowrap;
}

.color-white {
  color: $white;
}

.color-black {
  color: $black;
}

.color-info {
  color: $info;
}

.color-green-states {
  @include dark-theme {
    color: $green-states;
  }

  @include light-theme {
    color: darken($green-states, 15%);
  }
}

.color-dark-gray {
  color: $dark-gray;
}

.color-danger {
  color: $danger-states;
}

.color-warning {
  color: $warning;
}

.color-inverse {
  @include inverse-color;
}

.color-default {
  @include default-color;
}

.light {
  font-weight: 300;
}

.semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}
