
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.container {
  border-radius: 50%;

  @include light-theme {
    background: $light-background;
    background-image: linear-gradient(90deg, $light-background, darken($light-background, 5));

    &.shadow {
      box-shadow: 6rem 6rem 20rem 0 rgb(0 0 0 / 15%);
    }
  }

  @include dark-theme {
    background: $default;
    background-image: linear-gradient(90deg, $default, $default-states);

    &.shadow {
      box-shadow: 0 10rem 25rem 0 rgb(0 0 0 / 30%);
    }
  }
}

.size-52 {
  width: 52rem;
  height: 52rem;
  padding: 11rem;
}

.size-70 {
  width: 70rem;
  height: 70rem;
  padding: 10rem;
}

.size-75 {
  width: 75rem;
  height: 75rem;
  padding: 15rem;
}
