
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.container {
  transition: .1s;

  &.withHover {
    cursor: pointer;
  }
}

.background-gray svg {
  color: $gray-600;
}

.background-blue svg {
  color: $info;
}

.background-danger-states svg {
  color: $danger-states;
}

.background-warning svg {
  color: $warning;
}

.withHover {

  &.background-gray:hover svg {
    color: lighten($gray-600, 15%);
  }

  &.background-blue:hover svg {
    color: lighten($info, 15%);
  }
}

.background-default {
  @include default-color();
}

.background-white {
  color: $white;
}
