
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.button {
  position: relative;
  min-width: 170rem;
  padding: 10rem 12rem;
  overflow: hidden;
  background: linear-gradient(-45deg, $info-states, $info, $danger, $warning, $info-states, $info, $danger, $warning);
	background-size: 800% 800%;
  transition: .2s;
	animation: gradient 20s ease infinite;

  &.active {
    animation: none;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

@include mobile {

  .button {
    min-width: 120rem;
  }
}
