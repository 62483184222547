
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.tooltip {
  position: absolute;
  z-index: $layer-menu + 1;
  display: none;
  max-width: 330rem;
  overflow: hidden;
  border-radius: 7rem;
  box-shadow: 0 8rem 16rem 0 rgb(34, 42, 66, 20%);

  @include dark-theme {
    background-color: $default;
  }

  @include light-theme {
    background: $white;
  }

  &.zIndex {
    z-index: $layer-modal + 1;
  }
}

.visible {
  display: block;
  opacity: 1;
  @include animate-show-content();
}
