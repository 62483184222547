
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $layer-modal - 1;
  width: 100%;
  height: 100%;
  background-color: rgba(black, .65);
  opacity: 1;
  animation: show-content .4s ease;

  &.mounted {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

@keyframes show-content {
  from {
    background-color: rgba(black, 0);
  }
  to {
    background-color: rgba(black, .65);
  }
}
