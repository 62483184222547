
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.icon {
  padding: 7rem;
  background-image: linear-gradient(135deg, $warning, $warning-states);
  border-radius: 50%;
  cursor: pointer;
  transition: .2s;

  svg {
    color: $white;
  }

  &:hover,
  &.active {
    transform: scale(1.2);
  }

  &.active {
    background: $white;
    box-shadow: 0 0 10rem 0 rgb(0 0 0 / 20%);

    svg {
      color: $black;
    }
  }
}
