
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
$keys: (
  p : (padding),
  pt: (padding-top),
  pb: (padding-bottom),
  pr: (padding-right),
  pl: (padding-left),
  m : (margin),
  mt: (margin-top),
  mb: (margin-bottom),
  mr: (margin-right),
  ml: (margin-left),
);

$autoKeys: (
  mt: (margin-top),
  mb: (margin-bottom),
  mr: (margin-right),
  ml: (margin-left),
);

$radiuses: (4, 8, 12);
$offsets: (4, 8, 12, 16, 20, 24, 32, 40, 48, 56, 64, 72, 80);

// generate example – .pt_24 { padding-top: 24rem } etc
@each $key, $prop in $keys {
  @each $offset in $offsets {

    .#{$key}_#{$offset} {
      #{$prop}: #{$offset}rem;
    }
  }
}

// generate .mt_auto { margin-top: auto } etc
@each $key, $props in $autoKeys {
  .#{$key}_auto {
    @each $prop in $props {
      #{$prop}: auto;
    }
  }
}

// generate .radius_4 { border-radius: 4rem } etc
@each $radius in $radiuses {
  .radius-#{$radius} {
    border-radius: #{$radius}rem;
  }
}

.alignCenter {
  text-align: center;
}

.alignRight {
  text-align: right;
}

.alignLeft {
  text-align: left;
}

.overflow-hidden {
  overflow: hidden;
}

.center {
  margin-right: auto;
  margin-left: auto;
}

.inline {
  display: inline-block;
}

.relative {
  position: relative;
}

.hideEmpty {

  &:empty {
    display: none;
  }
}

.fullWidth {
  width: 100%;
}

.fullHeight {
  height: 100%;
}

.lineHeight0 {
  line-height: 0;
}
