
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.container {
  width: 200rem;
  background-image: linear-gradient(0deg, $info-states 0%, $info 100%);
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 200rem;
}

.title::before {
  position: absolute;
  bottom: 0;
  left: 15rem;
  width: 170rem;
  height: 1px;
  background-color: lighten($info, 13%);
  transition: .2s;
  content: '';
}

@include desktop {

  .root {
    position: absolute;
    top: 82rem;
    left: 0;
    z-index: $layer-menu;
    height: calc(100% - 80rem);
    transform: translateY(0);
    animation: show-content 1s ease-in-out;

    &::before {
      position: absolute;
      top: -5rem;
      left: 34rem;
      border-right: 6rem solid transparent;
      border-bottom: 8rem solid $info;
      border-left: 6rem solid transparent;
      content: '';
    }

    .container {
      height: 100%;
      border-radius: 8rem;
      box-shadow: 0 4rem 20rem 0 rgb(0 0 0 / 25%);
      transition: .2s;

      :global(#mainMenuItemText) {
        transition: .2s;
      }
    }

    &.compact {

      .container {
        width: 80rem;

        &:hover {
          width: 200rem;

          .title::before {
            width: 170rem;
          }

          :global(#mainMenuItemText) {
            opacity: 1;
          }
        }
      }

      .title::before {
        width: 50rem;
      }

      :global(#mainMenuItemText) {
        opacity: 0;
      }
    }
  }
}

@include except-desktop {

  .root {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $layer-menu;
    transition: .2s;

    &.hide {
      left: -200rem;
      opacity: 0;
    }
  }

  .container {
    height: 100vh;
  }
}

@keyframes show-content {
  from {
    transform: translateY(200%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
