
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.button {
  padding: 6rem;
  background: linear-gradient(135deg, $warning, $warning-states);
  border-radius: 50%;
  cursor: pointer;
  transition: transform .2s;
  @include animate-pulse;

  svg {
    color: $white;
  }

  &:hover {
    transform: scale(1.2);
  }
}
