
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.container {
  height: 100%;
}

.title {
  font-weight: 600;
  background: linear-gradient(45deg, $info, $danger);
  background-color: $info;
  background-repeat: repeat;
  background-size: 100%;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-clip: text;
}

@include mobile {

  .title {
    font-size: 60rem !important;
  }
}

@include except-mobile {

  .title {
    font-size: 100rem !important;
  }
}
