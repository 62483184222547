
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.loader {

  @include dark-theme {
    @include block-loader();
  }

  @include light-theme {
    @include block-loader(30rem, 4rem, gray);
  }
}

@include mobile {

  .transactions {
    margin: 24rem -24rem 0;
  }
}

@include tablet-portrait {

  .modal {
    width: 500rem;
  }
}


@include tablet-landscape {

  .modal {
    width: 700rem;
  }
}

@include desktop {

  .modal {
    width: 800rem;
  }
}
