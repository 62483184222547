
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.container {
  border-radius: 40rem;
  cursor: pointer;
  transition: .4s;

  @include dark-theme {
    border: solid 1px lighten($dark-background-states, 5%);
  }

  @include light-theme {
    border: solid 1px darken($white, 5%);
  }

  &::before {
    position: absolute;
    top: 0;
    right: 100%;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 40rem;
    opacity: 0;
    transition: .4s;
    content: '';

    @include dark-theme {
      background-image: linear-gradient(90deg, $dark-background-states, lighten($dark-background-states, 5%));
    }

    @include light-theme {
      background-image: linear-gradient(90deg, $white, darken($white, 5%));
    }
  }

  &:hover,
  &.loading {
    border-color: transparent;

    &::before {
      right: 0;
      opacity: 1;
    }

    .icon {
      transform: scale(1.18);
    }
  }

  &.loading .icon {
    position: relative;

    @include dark-theme {
      @include block-loader(28rem);
    }

    @include light-theme {
      @include block-loader(28rem, 4rem, $gray-500);
    }

    img {
      display: none !important;
    }
  }
}

.icon {
  position: relative;
  z-index: 10;
  transition: .3s;
}

.text {
  position: relative;
  z-index: 10;

  span {
    color: $info;
  }
}
