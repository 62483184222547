
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.notification {
  z-index: 20;
  width: 100%;
  animation: openNotification 0.5s ease-in-out;

  &.closed {
    z-index: 1;
    opacity: 0;
    transition: all 0.3s ease-out;
  }
}

.text {
  flex: 1 1 auto;
}

.error {
  background-color: darken($danger, 10%);
}

.success {
  background-color: darken($success, 10%);
}

.warning {
  background-color: $warning;
}

.info {
  background-color: $info;
}

@keyframes openNotification {
  from {
    transform: translate3d(0, -100%, 0);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
}
