
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.container {
  width: 36rem;
  height: 36rem;

  &,
  &.disabled {
    @include dark-theme {
      border: solid 1px lighten($dark-background-states, 5%);
    }

    @include light-theme {
      border: solid 1px darken($white, 5%);
    }
  }

  &.disabled svg {
    color: $dark-gray;
  }

  &:hover {
    border-color: $dark-gray;
  }
}
