
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.container {
  height: 75rem;
  cursor: pointer;
  transition: .2;

  @include dark-theme {
    border: solid 1px lighten($dark-background-states, 5%);

    &:hover {
      background-color: lighten($dark-background-states, 5%);
    }
  }

  @include light-theme {
    border: solid 1px darken($white, 3%);

    &:hover {
      background-color: darken($white, 3%);
    }
  }

  &.active {
    border-color: $info;
    cursor: default;

    &:hover {
      background-color: transparent;
    }
  }
}

.selectedTextContainer {
  position: absolute;
  bottom: -10rem;
  left: 0;
  width: 100%;
}

.selectedText {
  padding: 0 8rem;

  @include dark-theme {
    background-color: $dark-background-states;
  }

  @include light-theme {
    background-color: $white;
  }
}
